<template>
  <div class="" v-if="donation">
    <div class="mb-6 flex flex-row justify-start items-center">
      <BaseButton naked class="mr-4" @click="$router.push({name: 'donations'})"
        ><ArrowLeftIcon class="text-cdlblack dark:text-white"
      /></BaseButton>
      <BaseButton
        naked
        :progress="postsGenerating"
        label="generate posts"
        @click="generatePosts"
      />
    </div>

    <!-- transaction details -->
    <table>
      <tr v-for="row in donationDetails" :key="row.label">
        <td>{{ row.label }}</td>
        <td>{{ row.value }}</td>
      </tr>
    </table>

    <!-- posts -->
    <div class="mt-10">
      <h2 class="text-xl">Social posts [our]</h2>
      <div class="mt-5 flex flex-row items-start flex-wrap">
        <!-- our post -->
        <div
          class="w-60 h-60 bg-gray-300 flex flex-center"
          v-if="!posts.our.post.url"
        >
          <BaseLoading v-if="postIsGenerated('post-our-post')" />
          <span class="inline-block" v-else>not generated</span>
        </div>
        <img class="w-60" :src="posts.our.post.url" v-if="posts.our.post.url" />

        <!-- our story -->
        <div
          class="w-60 h-60 ml-4 bg-gray-300 flex flex-center"
          v-if="!posts.our.story.url"
        >
          <BaseLoading v-if="postIsGenerated('post-our-story')" />
          <span class="inline-block" v-else>not generated</span>
        </div>
        <img
          class="w-60 ml-4"
          :src="posts.our.story.url"
          v-if="posts.our.story.url"
        />
      </div>
    </div>
    <div class="mt-10">
      <h2 class="text-xl">Social posts [their]</h2>
      <div class="mt-5 flex flex-row items-start flex-wrap">
        <!-- their post -->
        <div
          class="w-60 h-60 bg-gray-300 flex flex-center"
          v-if="!posts.their.post.url"
        >
          <BaseLoading v-if="postIsGenerated('post-their-post')" />
          <span class="inline-block" v-else>not generated</span>
        </div>
        <img
          class="w-60"
          :src="posts.their.post.url"
          v-if="posts.their.post.url"
        />

        <!-- their story -->
        <div
          class="w-60 h-60 ml-4 bg-gray-300 flex flex-center"
          v-if="!posts.their.story.url"
        >
          <BaseLoading v-if="postIsGenerated('post-their-story')" />
          <span class="inline-block" v-else>not generated</span>
        </div>
        <img
          class="w-60 ml-4"
          :src="posts.their.story.url"
          v-if="posts.their.story.url"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mixinGlobal from "@/mixins/mixinGlobal"
import {ArrowLeftIcon} from "@vue-hero-icons/outline"
import {fn, storage} from "@/globals"
const generatePosts = fn.httpsCallable("calls-admin-generatePosts")

export default {
  name: "Donation",
  mixins: [mixinGlobal],
  components: {
    ArrowLeftIcon,
  },
  data() {
    return {
      donation: {},
      post: {
        kind: "partners/donation/cuddle",
        ops: ["our", "their"],
        layouts: ["post", "story"],
      },
      postsGenerating: false,
      posts: {
        our: {
          post: {
            url: null,
          },
          story: {
            url: null,
          },
        },
        their: {
          post: {
            url: null,
          },
          story: {
            url: null,
          },
        },
      },
    }
  },
  computed: {
    donations() {
      return this.$store.state.donations.donations
    },
    loadedDonations() {
      return this.$store.state.donations.loadingState.donations == "loaded"
    },
    donationDetails() {
      if (!this.donation.timestamp) return
      return [
        {
          label: "id",
          value: this.donation.id,
        },
        {
          label: "from",
          value: this.donation.from,
        },
        {
          label: "to",
          value: this.donation.to,
        },
        {
          label: "value",
          value: this.donation.value,
        },
        {
          label: "asset",
          value: this.donation.asset,
        },
        {
          label: "timestamp",
          value: this.donation.timestamp.toDate(),
        },
      ]
    },
  },
  async created() {},
  async mounted() {
    await this.getDonationFromDb()
    await this.getPostURLs()
  },
  methods: {
    getDonationFromDb() {
      let donation = this.donations.find(
        (p) => p.id == this.$route.params.donationId
      )
      this.donation = donation //JSON.parse(JSON.stringify(donation))
      // this.donation.id = donation.id
    },
    async generatePosts() {
      this.postsGenerating = true
      let response = await generatePosts({
        post: this.post,
        partnerId: this.donation.partnerId,
        donation: {
          ...this.donation,
          id: this.donation.id,
        },
      })
      await this.getPostURLs()
      this.postsGenerating = false
    },
    postIsGenerated(postName) {
      if (!this.donation.postsGenerated) return false
      return this.donation.postsGenerated.includes(postName)
    },
    // async getPostURL(postName) {
    //   let fileName = `${postName}.jpeg`
    //   let fullPath = `partners/${this.donation.partnerId}/partnerTransactions/${this.donation.id}/posts/${fileName}`
    //   let postPathRef = storage.ref(fullPath)
    //   let postUrl = await postPathRef.getDownloadURL()
    //   return postUrl
    // },
    async getPostURLs() {
      let posts = []
      this.post.ops.forEach((op) => {
        this.post.layouts.forEach((layout) => {
          posts.push({
            kind: this.post.kind,
            op,
            layout,
          })
        })
      })

      await Promise.all(
        posts.map(async (post) => {
          return new Promise(async (resolve, reject) => {
            let fileName = `post-${post.op}-${post.layout}.jpeg`
            let fullPath = `partners/${this.donation.partnerId}/posts/${post.kind.replaceAll('/','-')}/${this.donation.id}/${fileName}`
            let postPathRef = storage.ref(fullPath)
            console.log(fullPath)
            try {
              let postUrl = await postPathRef.getDownloadURL()
              console.log(postUrl)
              this.posts[post.op][post.layout].url = postUrl
              resolve()
            } catch (e) {
              console.error(e)
            }
          })
        })
      )
    },
  },
}
</script>

<style lang="less" scoped></style>
